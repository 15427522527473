.mini_team_login_page {
  width: 100%;
  height: 100%;
  background: url(https://www.mayun888.top/images/miniteam/login-bg.jpg)
    no-repeat center center;
  background-size: cover;
  position: relative;

  .login_page_content {
    width: 370px;
    height: 280px;
    background: rgba(255,255,255,1);
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 12px;

    .login-title {
      // background: #ffffff;
      line-height: 24px;
      font-size: 24px;
      padding: 12px 0;
      width: 100%;
      border-radius: 6px;
      text-align: center;
      color: #3d3c3c;
    }
    .login-buttons {
      width: 100%;
    }

    .login-page-user-register {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
