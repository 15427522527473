#root {
    height: 100%;
    width: 100%;
    overflow: auto;

    .xx_system_page {
        height: 100%;
        width: 100%;
        overflow: auto;
    }
}